import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [

  // {
  //   path: '/',
  //   redirect: '/niveles'
  // },
  {
    path: '/niveles/:centroLaboral',
    name: 'niveles',
    component: () => import('@/views/niveles/Lista.vue')
  },
  {
    path: '/nivel-agregar/:centroLaboral',
    name: 'nivel-agregar',
    component: () => import('@/views/niveles/Agregar.vue')
  },
  {
    path: '/nivel-editar/:id',
    name: 'nivel-editar',
    component: () => import('@/views/niveles/Editar.vue'),  
  },
  {
    path: '/areas/:nivel_id',
    name: 'areas',
    component: () => import('@/views/areas/Lista.vue'),
  },
  {
    path: '/areas-agregar/:nivel_id',
    name: 'areas-agregar',
    component: () => import('@/views/areas/Agregar.vue'),
  },
  {
    path: '/areas-editar/:id',
    name: 'areas-editar',
    component: () => import('@/views/areas/Editar.vue'),
  },
  {
    path: '/anomalias/:area_id',
    name: 'anomalias',
    component: () => import('@/views/anomalias/Lista.vue'),
  },
  {
    path: '/anomalias-agregar/:rnv_id/:area_id',
    name: 'anomalias-agregar',
    component: () => import('@/views/anomalias/Agregar.vue'),
  },
  {
    path: '/anomalias-editar/:id',
    name: 'anomalias-editar',
    component: () => import('@/views/anomalias/Editar.vue'),
  },
  {
    path: '/elementos-fisicos/:area_id',
    name: 'elementos-fisicos',
    component: () => import('@/views/anomalias/ElementosFisicos.vue'),
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes 
})

export default router
